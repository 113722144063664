/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled, { css } from 'styled-components'

// Images
import Triangle from 'img/triangle.inline.svg'
import Pin from 'img/pin.inline.svg'
import Phone from 'img/phone.inline.svg'
import Mail from 'img/mail.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import { motion, AnimatePresence } from 'framer-motion'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 1rem;
    position: relative;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #dadada;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding: 0 10px;
  }

  & .form-duuf-label {
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding-bottom: 5px;
  }

  & .form-duuf-error {
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 0;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-checkbox-consent {
    text-decoration: underline;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`
interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const StyledFormContact = styled.section``

const Title = styled(ParseContent)`
  & h1,
  h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 50px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & p {
    font-size: 16px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    & h1,
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const FormColumn = styled.div`
  padding: 100px 100px 100px 0;

  @media screen and (max-width: 1199px) {
    padding: 50px 50px 50px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 30px calc(var(--bs-gutter-x) * 0.5);
  }
`

const OfficeColumn = styled.div`
  padding: 150px 80px !important;
  background-color: ${({ theme }) => theme.color.grey};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 2000px;
    right: -2000px;
    background-color: ${({ theme }) => theme.color.grey};
    top: 0;
  }

  @media screen and (max-width: 1199px) {
    padding: 80px 50px !important;
  }

  @media screen and (max-width: 991px) {
    padding: 30px calc(var(--bs-gutter-x) * 0.5) !important;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 2000px;
      left: -2000px;
      background-color: ${({ theme }) => theme.color.grey};
      top: 0;
    }
  }
`

const OfficeColumnTitle = styled(ParseContent)`
  & p {
    font-size: 20px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.primary};
  }
`

const Office = styled.div``

const OfficeTitle = styled(ParseContent)`
  & p {
    font-size: 16px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const SvgWrapper = styled.div<{ active: boolean }>`
  ${({ active }) =>
    active &&
    css`
      & svg {
        transform: rotate(180deg);
      }
    `}
`

const OfficeInfo = styled(motion.div)`
  position: relative;
  overflow: hidden;

  & > div {
    padding: 10px 0 0 20px;
  }

  & p,
  a {
    font-size: 16px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.dark};
  }

  & a:hover {
    text-decoration: underline;
  }
`

const OfficeSvgWrapper = styled.div`
  width: 25px;

  & svg {
    path {
      fill: ${({ theme }) => theme.color.dark};
    }
  }
`

const FormContact: React.FC<FormContactProps> = ({ fields }) => {
  const [active, setActive] = React.useState(0)

  return (
    <StyledFormContact>
      <div className="container">
        <div className="row">
          <FormColumn className="col-lg-6">
            <Title content={fields.description || ''} />
            <StyledFormDuuf
              privacyUrl={'/privacy-policy/'}
              privacyUrlPlacement="privacy policy"
              id={1}
              className="mt-lg-5 mt-2"
              generate={false}
            >
              <div className="row">
                <div className="col-6">
                  <FormDuufGroup showIds={[4]} />
                </div>
                <div className="col-6">
                  <FormDuufGroup showIds={[8]} />
                </div>
                <div className="col-12">
                  <FormDuufGroup hideIds={[4, 8]} />
                </div>
              </div>
              <FormDuufSubmit />
            </StyledFormDuuf>
          </FormColumn>
          <OfficeColumn className="col-lg-6">
            <OfficeColumnTitle content={fields.officestitle || ''} />
            <div className="pt-lg-4">
              {fields.offices?.map((office, index) => (
                <Office className="py-lg-3 py-2" key={index}>
                  <div
                    role="button"
                    tabIndex={-1}
                    onKeyDown={() => setActive(index)}
                    onClick={() => setActive(index)}
                    className="d-flex align-items-center"
                  >
                    <OfficeTitle content={office?.name || ''} />
                    <SvgWrapper active={active === index} className="ms-2 pb-1">
                      <Triangle />
                    </SvgWrapper>
                  </div>
                  <AnimatePresence>
                    {active === index && (
                      <OfficeInfo
                        initial={{ height: 0 }}
                        animate={{ height: 'min-content' }}
                        exit={{ height: 0 }}
                      >
                        <div>
                          <div className="d-flex">
                            <OfficeSvgWrapper>
                              <Pin />
                            </OfficeSvgWrapper>
                            <ParseContent content={office?.address || ''} />
                          </div>
                          {/* <div className="pt-lg-4 pt-2 align-items-center d-flex">
                            <OfficeSvgWrapper>
                              <Phone />
                            </OfficeSvgWrapper>
                            <div>
                              <a href={office?.phone?.url}>
                                {office?.phone?.title}
                              </a>
                            </div>
                          </div>
                          <div className="align-items-center d-flex">
                            <OfficeSvgWrapper>
                              <Mail />
                            </OfficeSvgWrapper>
                            <div>
                              <a href={office?.mail?.url}>
                                {office?.mail?.title}
                              </a>
                            </div>
                          </div> */}
                        </div>
                      </OfficeInfo>
                    )}
                  </AnimatePresence>
                </Office>
              ))}
            </div>
          </OfficeColumn>
        </div>
      </div>
    </StyledFormContact>
  )
}

export default FormContact
