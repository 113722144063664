/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    width: 100%;
    /* margin-bottom: 1rem; */
    position: relative;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #dadada;
    border-style: solid;
    border-width: 1px;
    min-height: 43px;
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding: 0 10px;
  }

  & .form-duuf-label {
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.light};
    padding-bottom: 5px;
  }

  & .form-duuf-error {
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 0;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.color.dark};
  }

  & .form-duuf-checkbox-consent {
    text-decoration: underline;
  }

  & .form-duuf-group-submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    height: 43px;
    white-space: nowrap;

    & button {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`
interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const StyledFormContact = styled.section`
  background-color: ${({ theme }) => theme.color.secondary};
  padding: 35px 0;

  @media screen and (max-width: 1199px) {
    padding: 25px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 25px 0;
  }
`

const Title = styled(ParseContent)`
  & h1,
  h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 40px;
    line-height: 50px;
    position: relative;
    color: #ffffff;
  }

  & p {
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  @media screen and (max-width: 575px) {
    & h1,
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const FormColumn = styled.div``

const UpToDate: React.FC<FormContactProps> = ({ fields }) => {
  const [active, setActive] = React.useState(0)

  return (
    <StyledFormContact>
      <div className="container">
        <div className="row gap-3">
          <div className="col-lg-12 d-flex align-items-center">
            <Title content={fields.description || ''} />
          </div>
          <FormColumn className="col-lg-12 d-flex align-items-center">
            {/* @ts-ignore */}
            <StyledFormDuuf
              privacyUrl={'/privacy-policy/'}
              privacyUrlPlacement="privacy policy"
              id={4}
              generate={false}
            >
              <div className="row">
                <div className="col-6 d-flex align-items-center">
                  <FormDuufGroup showIds={[1]} />
                </div>
                <div className="col-6 d-flex align-items-center">
                  <FormDuufSubmit />
                </div>
              </div>
            </StyledFormDuuf>
          </FormColumn>
        </div>
      </div>
    </StyledFormContact>
  )
}

export default UpToDate
